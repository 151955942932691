import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Hero(props) {
  return (
    <div className="relative sm:h-[640px] h-[740px]">
      <div className="absolute top-0 bottom-0 left-0 right-0 -z-20">
        <GatsbyImage
          image={props.heroSection.bgImage.asset.gatsbyImageData}
          alt="camp-hero"
          placeholder="blurred"
          className="sm:h-[640px] h-[740px] w-full"
        />
      </div>

      <div className="absolute top-0 bottom-0 right-0 left-10 -z-10">
        <StaticImage
          src="../../images/campground-overlay.png"
          alt="camp-hero"
          placeholder="blurred"
          className="sm:h-[640px] h-[740px]"
        />
      </div>

      <div className="container h-full text-white">
        <div className="flex items-center h-full">
          <div className="max-w-[674px]">
            <h1 className="text-[35px] sm:text-[64px] sm:leading-[82px] leading-[52px]">
              {props.heroSection.title}
            </h1>
            <h2 className="text-xl sm:text-[36px] sm:leading-[46px] leading-[36px]">
              {props.heroSection.subTitle}
            </h2>
            <p className="mt-6 text-base sm:text-[20px] leading-[25px] font-lora font-normal">
              {props.heroSection.caption}
            </p>
            <div className="mt-8">
              <a
                href="http://campwordsmith.com/"
                target="_blank"
                className="transition-all duration-300 bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11 hover:text-yellow-900 rounded-lg"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
